@import '@/scss/variables.scss';
@import '~vuetify/src/styles/styles.sass';

.dashboard-date {
  background-color: #bf360c;
}
.project-date {
  background-color: #12005e;
}
.chat-msg {
  overflow-y: scroll;
  height: calc(100% - 8rem);
  width: 100%;
}

.chat-bottom {
  width: 100%;
  height: 100%;
}

.msg {
  background-color: #cccccc;
  color: black;
  border-radius: 10px;
  padding: 5px;
  max-width: 70%;
}

.projects-personal-msg {
  background-color: rgb(99, 100, 144);
  color: white;
  border-radius: 10px;
  padding: 5px;
  max-width: 70%;
}

.dashboard-personal-msg {
  background-color: #ef6c00;
  color: white;
  border-radius: 10px;
  padding: 5px;
  max-width: 70%;
}

.msg-text-wrapper {
  width: 100%;
}

.opacity-enter {
  opacity: 0;
}

.opacity-enter-active {
  transition: all 0.7s ease-out;
}
